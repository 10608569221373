<template>
  <div class="page-wrapper container">
    <h1 class="page-title" data-aos="fade-up">Exhibitions</h1>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <MsgEntityCollectionEmpty v-if="!loadingExhibitions && emptyExhibitions" name="Exhibitions" />

    <PageItemsGroup
      v-if="museumExhibitions.length"
      :base-type="'exhibitions'"
      :has-more="getMore.museumExhibitions"
      :items="museumExhibitions"
      :title="'museums'"
      :type="'museumExhibitions'"
      @load-more="loadMore"
    />
    <PageItemsGroup
      v-if="galleryExhibitions.length"
      :base-type="'exhibitions'"
      :has-more="getMore.galleryExhibitions"
      :items="galleryExhibitions"
      :title="'galleries'"
      :type="'galleryExhibitions'"
      @load-more="loadMore"
    />

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import { navigatorCurrentPosition } from '@/helpers/GeolocationHelper';
import ExhibitionDataFilterHelper from '@/helpers/data-filter/ExhibitionDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import exhibitionsByMuseumQuery from '@/graphql/exhibition/ExhibitionsByMuseum.query.gql';
import exhibitionsByGalleryQuery from '@/graphql/exhibition/ExhibitionsByGallery.query.gql';
import exhibitionsPageFilterQuery from '@/graphql/exhibition/ExhibitionsPageFilter.query.gql';

import PageItemsGroup from '@/components/partials/PageItemsGroup';
import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const DATA_PER_PAGE = 6;
const MUSEUM_EXHIBITIONS_TYPE = 'museumExhibitions';
const GALLERY_EXHIBITIONS_TYPE = 'galleryExhibitions';

export default {
  name: 'ExhibitionsPage',
  components: {
    MsgEntityCollectionEmpty,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    PageItemsGroup,
  },
  metaInfo: {
    title: 'Exhibitions',
  },
  data() {
    return {
      description: '',
      museumExhibitions: [],
      galleryExhibitions: [],
      loading: {
        museumExhibitions: true,
        galleryExhibitions: true,
      },
      getMore: {
        museumExhibitions: false,
        galleryExhibitions: false,
      },
      page: {
        museumExhibitions: 1,
        galleryExhibitions: 1,
      },
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },
  computed: {
    emptyExhibitions() {
      return !this.museumExhibitions.length && !this.galleryExhibitions.length;
    },
    filterQueryVariables() {
      return ExhibitionDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    loadingExhibitions() {
      return this.loading.museumExhibitions || this.loading.galleryExhibitions;
    },
  },
  created() {
    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
      }
    );

    this.filterValues = ExhibitionDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.fetchAllExhibitions();
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = ExhibitionDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.fetchAllExhibitions();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.exhibition,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
  },
  methods: {
    fetchAllExhibitions() {
      this.fetchExhibitionsData(MUSEUM_EXHIBITIONS_TYPE);
      this.fetchExhibitionsData(GALLERY_EXHIBITIONS_TYPE);
    },
    fetchExhibitionsData(type) {
      this.loading[type] = true;
      const query = type === MUSEUM_EXHIBITIONS_TYPE ? exhibitionsByMuseumQuery : exhibitionsByGalleryQuery;
      this.$apollo
        .query({
          query,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            page: this.page[type],
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          const exhibitionsPage =
            type === MUSEUM_EXHIBITIONS_TYPE ? data.listMuseumExhibitions : data.listGalleryExhibitions;

          this[type].push(...exhibitionsPage.data);
          this.getMore[type] = exhibitionsPage.hasMorePages;
          this.loading[type] = false;
        })
        .catch(() => {
          this.loading[type] = false;
        });
    },
    getFilterData() {
      return this.$apollo
        .query({
          query: exhibitionsPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = {} }) => {
          this.filterGroups = ExhibitionDataFilterHelper.prepareFilterGroups(data);
        });
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = ExhibitionDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMore(type) {
      if (this.getMore[type]) {
        this.page[type]++;
        this.fetchExhibitionsData(type);
      }
    },
    resetFetchDataOptions() {
      this.loading.museumExhibitions = true;
      this.loading.galleryExhibitions = true;
      this.page.museumExhibitions = 1;
      this.page.galleryExhibitions = 1;
      this.museumExhibitions = [];
      this.galleryExhibitions = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;

  @media (max-width: 767px) {
    .open {
      position: fixed;
      top: 0;
      padding-top: 20px;
      left: 0;
    }
  }
}
.page-desc {
  max-width: 550px;
}
</style>
